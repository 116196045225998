import StatsBox, { StatData } from 'components/ui/StatsBox/StatsBox'
import { totalModuloImport } from 'utils/handleModuloInfo'
import { redondearDecimalesToLocaleString } from 'utils/roundNumber'
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol'
import EngineeringIcon from '@mui/icons-material/Engineering'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import { useTotalMo } from 'hooks/useTotalMo'
import { useGetQueryDataRq } from 'hooks/useGetQueryDataRq'
import { useParams } from 'react-router-dom'

const StatsSingleModulo = ({ celulasInfo }) => {
  const { idProject, moduloId } = useParams()
  const { totalMoByModulo, } = useTotalMo()
  const data = useGetQueryDataRq(['instances-modulo', idProject, moduloId])

  return (
    <>
      <h2 className="stats-modulo-title">
        Total matrícula ({data.data.instances.length} vínculos):
      </h2>
      <div className="modulo-stats-container">
        {/* TOTAL */}
        <StatsBox title="Importe artículos" Icon={WorkspacesIcon}>
          <StatData>
            {redondearDecimalesToLocaleString(totalModuloImport(celulasInfo))}€
          </StatData>
        </StatsBox>
        <StatsBox title="Coste mano de obra" Icon={EngineeringIcon}>
          <StatData>
            {redondearDecimalesToLocaleString(
              totalMoByModulo(data.data.instances.length)
            )}
            €
          </StatData>
        </StatsBox>
        <StatsBox title="Importe total" Icon={EuroSymbolIcon}>
          <StatData>
            {redondearDecimalesToLocaleString(
              totalModuloImport(celulasInfo) +
                totalMoByModulo(data.data.instances.length)
            )}
            €
          </StatData>
        </StatsBox>
      </div>
    </>
  )
}

export default StatsSingleModulo
