import { Page, Text, View, Document, Image } from '@react-pdf/renderer'
import JitLogo from './../../assets/jit-logo.png'
import JitLogoBlack from './../../assets/jit-logo-black.png'
import { stylesPdf } from './FabricaPdf.styles'

const FabricaPDF = ({
  modulos,
  today,
  titlePdf,
  totalImportProject,
  totalMoByVinculo,
}) => {
  const { modulos: data } = modulos

  const formatNumber = num => {
    const [integerPart, decimalPart] = num.split('.')
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    return decimalPart ? `${formattedInteger},${decimalPart}` : formattedInteger
  }
  return (
    <Document>
      <Page size="A4" style={stylesPdf.page}>
        <View style={stylesPdf.section}>
          <Image src={JitLogo} style={stylesPdf.imageHeader} />
        </View>
        <View style={stylesPdf.titleContainer}>
          <View style={stylesPdf.titleWraper}>
            <Text style={stylesPdf.title}>Resumen Fábrica - {titlePdf}</Text>
            <Text style={{ fontSize: 10 }}>{today}</Text>
          </View>
          <Text style={stylesPdf.subtitle}>
            Total matrículas: {data?.length}
          </Text>
        </View>
        <Text>
          <Text style={stylesPdf.projectImport}>
            Importe total: {formatNumber(totalImportProject.toFixed(2))} €
          </Text>
        </Text>
        {data.map(item => (
          <View key={item.code} style={stylesPdf.cardContainer} wrap={false}>
            <View style={stylesPdf.cardTitle}>
              <Text style={stylesPdf.textBold}>{item.code}</Text>
              <Text>({item.instances.length} unidades)</Text>
            </View>
            <View style={stylesPdf.container}>
              <Text style={stylesPdf.textData}>
                Precio unitario:{' '}
                {formatNumber(
                  (
                    item?.totalImport / item?.instances?.length +
                    totalMoByVinculo
                  )?.toFixed(2)
                )}{' '}
                €
              </Text>
              <Text style={stylesPdf.textData}>
                Precio total:{' '}
                {formatNumber(
                  (
                    item?.totalImport +
                    totalMoByVinculo * item?.instances?.length
                  ).toFixed(2)
                )}{' '}
                €
              </Text>
            </View>
          </View>
        ))}
        <Text
          style={stylesPdf.pageNumbers}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
        <Image src={JitLogoBlack} style={stylesPdf.imageFooter} fixed />
        <Text style={stylesPdf.footer} fixed>
          Mediciones
        </Text>
      </Page>
    </Document>
  )
}

export default FabricaPDF
