import { PDFDownloadLink } from '@react-pdf/renderer'
import FabricaPDF from './FabricaPDF'
import { CustomButton } from 'components/ui'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useTotalMo } from 'hooks/useTotalMo'

const FabricaPdfButton = ({ modulos }) => {
  const { idProject } = useParams()
  const queryClient = useQueryClient()
  const { totalMo, totalMoByVinculo } = useTotalMo()
  const { importProject } = modulos

  const data = queryClient.getQueryData(['project', idProject])

  const fechaActual = new Date()
  const dia = String(fechaActual.getDate()).padStart(2, '0')
  const mes = String(fechaActual.getMonth() + 1).padStart(2, '0') // Los meses van de 0 a 11
  const anio = fechaActual.getFullYear()

  const fechaFormateada = `${dia}/${mes}/${anio}`
  const titlePdf = `${data?.data?.proyecto?.name}_${fechaFormateada}`
  return (
    <div className="button-container-pdf">
      <CustomButton>
        <PDFDownloadLink
          document={
            <FabricaPDF
              modulos={modulos}
              today={fechaFormateada}
              titlePdf={data?.data?.proyecto?.name}
              totalImportProject={importProject + Number(totalMo)}
              totalMoByVinculo={totalMoByVinculo}
            />
          }
          fileName={`${titlePdf}.pdf`}
          style={{ textDecoration: 'none', color: 'white' }}
        >
          {({ blob, url, loading, error }) =>
            loading ? 'Cargando pdf...' : 'PDF'
          }
        </PDFDownloadLink>
      </CustomButton>
    </div>
  )
}

export default FabricaPdfButton
