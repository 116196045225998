import { getDescompuestoByCode, getDescompuestoById } from '../api/jitApi'

import {
  createMultipleArticulos,
  getArticuloByCode,
  getArticuloById,
} from 'api/estructuraCostes/articulosJitApi'
import {
  eliminarDuplicadosPorPropiedad,
  ordenarPorPropiedad,
} from './formatText'
import { getBulkPropertiesAsync } from './getPropertiesViewer'
import { fromMtoMm } from './handleUnit'
import { viewer } from './launchViewer'
import { checkIformulaIsValid } from './checkIfFormulaIsValid'
import { MODULO_PARAM } from './constant/constantsModel'

const checkIfRulesAreCorrect = async (dbid, rules) => {
  let values = await getBulkPropertiesAsync(
    viewer,
    [dbid],
    [...rules.map(rule => rule.name)]
  )

  let rulesAreCorrect = true
  for (let i = 0; i < rules.length; i++) {
    const rule = rules[i]
    const value = values[0].properties.find(
      property => property.displayName == rule.name
    ).displayValue

    // * Tiene en cuenta si es number o string
    if (value != rule.value) {
      rulesAreCorrect = false
      break
    }
  }

  return rulesAreCorrect
}

const handleTypeBoq1 = async (group, dbid, bimLink) => {
  try {
    const articulosWithQuantity = await Promise.all(
      group.articulos.map(articulo =>
        singleBoqByArticulo(dbid, articulo, bimLink)
      )
    )

    return articulosWithQuantity.filter(item => item !== null)
  } catch (e) {
    console.log(e)
  }
}

const singleBoqByArticuloType3 = async (dbid, articulo, bimlink) => {
  const matches = articulo.formula.match(/%([^%]+)%/g)

  var properties = matches
    ? matches.map(match => match.substring(1, match.length - 1))
    : []

  let values = await getBulkPropertiesAsync(
    viewer,
    [Number(dbid)],
    [...properties, 'externalId']
  )

  values = values[0]

  const formatProperties = values.properties.map(property => {
    return {
      displayName: property.displayName,
      displayValue: fromMtoMm(property.displayValue).toFixed(0),
    }
  })

  const sortFormatProperties = formatProperties.sort(
    ordenarPorPropiedad('displayName')
  )

  return {
    externalid: values.externalId,
    properties: sortFormatProperties,
    actividad: articulo.actividad,
    bimlink: bimlink._id,
    quantity: 1,
  }
}

const handleTypeBoq2 = async (group, dbid, bimLink) => {
  const isCorrect = await checkIfRulesAreCorrect(dbid, group.rules)

  if (!isCorrect) {
    return
  }

  const articulosWithQuantity = await Promise.all(
    group.articulos.map(articulo =>
      singleBoqByArticulo(dbid, articulo, bimLink)
    )
  )

  return articulosWithQuantity.filter(item => item !== null)
}

const createSingleDescompuestoTypeBoq3 = async (
  articuloInBd,
  articuloToCreate
) => {
  const { properties, ...rest } = articuloToCreate

  const codeToCheck = `${articuloInBd.code}-${articuloToCreate.properties
    .map(art => art.displayValue)
    .join('-')}`

  const articulo = await getArticuloByCode(codeToCheck)

  if (!articulo) {
    // Si no existe, creo el artículo y luego el boq
    const joinProperties = articuloToCreate.properties
      .map(prop => `${prop.displayName} ${prop.displayValue}`)
      .join(' ')
    const nameToNewArticulo = `${articuloInBd.name} ${joinProperties}`
    const descompuestoToCreate = {
      code: codeToCheck,
      name: nameToNewArticulo,
      unit: articuloInBd.unit,
      precio: articuloInBd.precio || 50,
    }
    return descompuestoToCreate
  }
  return null
}

const createDescompuestosByGroupTypeBoq3 = async (group, dbid, bimLink) => {
  const [articulosFromBd, articulosWithProperties] = await Promise.all([
    Promise.all(
      group.articulos.map(articulo => getDescompuestoById(articulo.articulo))
    ),
    Promise.all(
      group.articulos.map(articulo =>
        singleBoqByArticuloType3(dbid, articulo, bimLink)
      )
    ),
  ])

  const data = await Promise.all(
    articulosFromBd.map((articulo, index) =>
      createSingleDescompuestoTypeBoq3(articulo, articulosWithProperties[index])
    )
  )

  return data
}

const createArticuloByGroupTypeBoq3 = async (group, dbid, bimLink) => {
  const [articulosFromBd, articulosWithProperties] = await Promise.all([
    Promise.all(
      group.articulos.map(articulo => getArticuloById(articulo.articulo))
    ),
    Promise.all(
      group.articulos.map(articulo =>
        singleBoqByArticuloType3(dbid, articulo, bimLink)
      )
    ),
  ])

  const data = await Promise.all(
    articulosFromBd.map((articulo, index) =>
      createSingleDescompuestoTypeBoq3(articulo, articulosWithProperties[index])
    )
  )

  return data
}

const getFormatBoqByType3 = async (articuloInBd, articuloToCreate) => {
  const { properties, ...rest } = articuloToCreate

  const codeToCheck = `${articuloInBd.code}-${articuloToCreate.properties
    .map(art => art.displayValue)
    .join('-')}`

  const articulo = await getDescompuestoByCode(codeToCheck)

  return {
    ...rest,
    articulo: articulo._id,
  }
}

const handleTypeBoq3 = async (group, dbid, bimLink) => {
  // Por cada artículo del array artículos, traigo su artículo.
  const [articulosFromBd, articulosWithProperties] = await Promise.all([
    Promise.all(
      group.articulos.map(articulo => getDescompuestoById(articulo.articulo))
    ),
    Promise.all(
      group.articulos.map(articulo =>
        singleBoqByArticuloType3(dbid, articulo, bimLink)
      )
    ),
  ])

  const data = await Promise.all(
    articulosFromBd.map((articulo, index) =>
      getFormatBoqByType3(articulo, articulosWithProperties[index])
    )
  )

  return data
}

const getModuloByProperty = async (boq, modulos) => {
  const { dbId } = boq
  const matchValue = await getBulkPropertiesAsync(viewer, [dbId], MODULO_PARAM)

  if (
    matchValue.length > 0 &&
    matchValue[0].properties[0].displayValue !== ''
  ) {
    const modulo_code_dv = matchValue[0].properties[0].displayValue
    console.log('modulo_code_dev', modulo_code_dv)
    // * Hay que dividir por "_" y quedarse con el segundo elemento
    // * Ejemplo de código: 007VS_C03a.03.31
    const modulo_code_ = modulo_code_dv.split('_')[1]
    if (!modulo_code_) return null
    const modulo_code = modulo_code_.split('.')[0]
    if (!modulo_code) return null
    const moduloMatched = modulos.find(modulo => modulo.code === modulo_code)
    if (!moduloMatched) return null

    const newBoq = { ...boq, modulotype: moduloMatched._id }
    return newBoq
  }
}

const getModuloFromBoq = async (boq, modulos) => {
  const moduloByCode = await getModuloByProperty(boq, modulos)
  if (moduloByCode) return moduloByCode
  const moduloBoqId = boq.externalid.split('/')[0]
  const modulo = modulos.find(m => m.instances.find(i => i === moduloBoqId))
  if (!modulo) {
    console.warn(
      `El dbId ${boq.dbId} con matrícula ${moduloBoqId} asignada no coincide con ninguna matrícula visible en el modelo`
    )
    return null
  }
  const newBoq = { ...boq, modulotype: modulo._id }
  return newBoq
}

const addModuloToBoq = async (boqs, modulos) => {
  const allBoqsWithModulos = await Promise.all(
    boqs.map(boq => getModuloFromBoq(boq, modulos))
  )
  return allBoqsWithModulos
}

const singleBoqByArticulo = async (dbid, articulo, bimlink) => {
  // * Comprobar que la fórmula esté bien construida. Si no está bien construida, mostrar algún mensaje de error o algo.
  const isFormulaValida = checkIformulaIsValid(articulo.formula)
  if (!isFormulaValida) {
    console.warn(
      `La fórmula ${articulo.formula} y bimlink ${bimlink._id} del dbId ${dbid} es invalida`
    )
    return null
  }
  // const matches =
  //   articulo.formula === 'Longitud%'
  //     ? ['%Longitud%']
  //     : articulo.formula.match(/%([^%]+)%/g)

  const matches = articulo.formula.match(/%([^%]+)%/g)

  let properties = matches
    ? matches.map(match => match.substring(1, match.length - 1))
    : []

  // * Comprobamos si la fórmula tiene algún parámetro por el que hay que scar displayValues. Si no es así, solo ejecutamos la fórmula.
  if (properties.length === 0) {
    let valuesNotMached = await getBulkPropertiesAsync(
      viewer,
      [dbid],
      ['externalId']
    )

    const quantity = eval(articulo.formula)

    return {
      externalid: valuesNotMached[0].externalId,
      articulo: articulo.articulo,
      actividad: articulo.actividad,
      bimlink: bimlink?._id,
      dbId: dbid,
      quantity: Number(quantity.toFixed(4)),
    }
  } else {
    let values = await getBulkPropertiesAsync(
      viewer,
      [dbid],
      [...properties, 'externalId']
      // articulo.formula === 'Longitud%'
      //   ? ['Longitud', 'externalId']
      //   : [...properties, 'externalId']
    )

    values = values[0]

    // * Comprobamos si exixten todos los parámetros para este dbId. Si no, retornamos ya que petará la función
    if (values.properties.length !== properties.length) {
      console.warn(
        `El dbId ${dbid} con bimlink ${bimlink._id} y articulo ${articulo._id} no tiene una o más propiedades para la fórmula ${articulo.formula}. ${values.properties} así que no se va a añadir.`
      )
      return null
    }

    // let formulaWithValues =
    //   articulo.formula === 'Longitud%' ? '%Longitud%' : articulo.formula

    let formulaWithValues = articulo.formula

    for (let i = 0; i < properties.length; i++) {
      formulaWithValues = formulaWithValues.replace(
        `%${properties[i]}%`,
        values.properties.find(
          property => property.displayName === properties[i]
        )?.displayValue ||
          values.properties.find(
            property => property.attributeName === properties[i]
          )?.displayValue
      )
    }

    const quantity = eval(formulaWithValues)

    return {
      externalid: values.externalId,
      articulo: articulo.articulo,
      actividad: articulo.actividad,
      bimlink: bimlink._id,
      dbId: dbid,
      quantity: Number(quantity.toFixed(4)),
    }
  }
}

const createArticulosByType3 = async (dbid, bimLink) => {
  if (!bimLink) {
    console.log(`El bimLink del dbId ${dbid} no se encuentra registrado en BD`)
    return
  }
  const descompuestosToCreatePromise = bimLink.groups.map(async group => {
    if (group.typeBoq == 3) {
      return createArticuloByGroupTypeBoq3(group, dbid, bimLink)
    }
  })

  const descompuestoToCreate = await Promise.all(descompuestosToCreatePromise)

  return descompuestoToCreate
}

const handleCreateBoq = async valuesWithBimlinks => {
  // * Añadir los articulos a BD
  // const bimLinksBDWithNotNull = bimLinksBD.filter(bimLink => bimLink !== null)
  const boQWithQuantity = await Promise.all(
    valuesWithBimlinks.map(async value =>
      createBoqByProperty(value.dbId, value.tipobimlink.bimlink)
    )
  )

  // * Quitar los undefined del array (cuando no se cumplen la rules)
  return boQWithQuantity
    .flat()
    .filter(boq => boq !== undefined)
    .flat()
}

const handleCreateArticulosByGroup3 = async valuesWithBimlinks => {
  const articulosToCreate = await Promise.all(
    valuesWithBimlinks.map(async value =>
      createArticulosByType3(value.dbId, value.tipobimlink.bimlink)
    )
  )

  const articulosToCreateFlat = articulosToCreate
    .flat()
    .filter(boq => boq !== undefined)
    .flat()
    .filter(boq => boq !== null)

  if (articulosToCreateFlat.length == 0 || articulosToCreateFlat == null) return
  // // * Quitar duplicados
  const articulosToCreateWithoutDuplicates = eliminarDuplicadosPorPropiedad(
    articulosToCreateFlat,
    'code'
  )
  const articulosCreated = await createMultipleArticulos(
    articulosToCreateWithoutDuplicates
  )
}

const createBoqByProperty = async (dbid, bimLink) => {
  if (!bimLink) {
    console.log(`El bimLink del dbId ${dbid} no se encuentra registrado en BD`)
    return
  }
  const allArticulosPromises = bimLink.groups.map(async group => {
    if (group.typeBoq === 2) {
      return handleTypeBoq2(group, dbid, bimLink)
    } else if (group.typeBoq === 3) {
      return handleTypeBoq3(group, dbid, bimLink)
    } else if (group.typeBoq === 1) {
      return handleTypeBoq1(group, dbid, bimLink)
    }
  })

  const articulosWithQuantity = await Promise.all(allArticulosPromises)
  return articulosWithQuantity
}

export {
  createBoqByProperty,
  addModuloToBoq,
  handleCreateArticulosByGroup3,
  handleCreateBoq,
}
