import axios from 'axios'

const getModuloC = async (projectId, moduloId) => {
  try {
    const { data } = await axios.get(`/api/modulos/${projectId}/${moduloId}`)
    console.log('data getModuloC', data.data)
    return data.data
  } catch (e) {
    throw new Error(e)
  }
}

const getModuloByProjectAndVersion = async (
  projectId,
  currentVersion,
  options = {}
) => {
  // console.log('currentVersion', currentVersion)
  try {
    // const currentVersion = getCurrentModelVersion()
    const { data } = await axios.get(
      `/api/modulos/${projectId}/${currentVersion}`
    )

    if (options.setModulosAreLoaded) {
      options.setModulosAreLoaded(true)
    }

    return data.data
  } catch (e) {
    throw new Error(e)
  }
}

export { getModuloC, getModuloByProjectAndVersion }
