import React, { useContext, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { viewer } from '../../utils/launchViewer'

import { useHandleUrl } from '../../hooks/useHandleUrl'
import { useTotalMo } from 'hooks/useTotalMo'
import ArticulosTableLoader from '../Loaders/ArticulosTableLoader'
import { getBulkPropertiesAsync } from '../../utils/getPropertiesViewer'
import {
  createMultipleBoq,
  deleteAllBoqByProjectAndVersion,
} from '../../api/jitApi'
import {
  addModuloToBoq,
  handleCreateArticulosByGroup3,
  handleCreateBoq,
} from '../../utils/handleBoq'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-hot-toast'
import TotalMo from '../Articulos/TotalMo'
import { CustomButton } from '../ui'
import FabricaMatriculasTable from './FabricaTable/FabricaMatriculasTable'
import AddIcon from '@mui/icons-material/Add'
import AdjustIcon from '@mui/icons-material/Adjust'
import MarginIcon from '@mui/icons-material/Margin'
import { getModuloByProjectAndVersion } from 'api/estructuraCostes/modulosJitApi'
import { redondearDecimalesToLocaleString } from 'utils/roundNumber'
import { getAllTiposByProyecto } from 'api/estructuraCostes/tiposJitApi'
import { ViewerContext } from 'context/ViewerContext'
import { useVersionModel } from 'hooks/useVersionModel'
import { expandTipos } from 'utils/handleTipos'
import FabricaPdfButton from 'components/pdf/FabricaPdfButton'

const Fabrica = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { currentVersion } = useVersionModel()
  const location = useLocation()
  const { changeLocation } = useHandleUrl()
  const { idProject } = useParams()
  const { totalMo } = useTotalMo()
  const { externalIdsMapping } = useContext(ViewerContext)

  const { data: modulos, isFetching } = useQuery(
    ['modulos-articulos', idProject, currentVersion],
    () => getModuloByProjectAndVersion(idProject, currentVersion)
  )

  const handleOnBoq = async () => {
    const loadingToast = toast.loading('Creando Boq')
    // * Sacar todos los elementos que tengan el parámetro BimLink
    try {
      // *: Sacar todos los tipobimlinkproyectos del proyecto
      const bimlinks = await getAllTiposByProyecto(idProject, { visible: true })

      // * Por cada externalId que hay dentro del tipo, crear un objeto con las mismas propiedades que dicho tipo, modificando el array del tipoExternalId por el string de dicho externalId
      const expandedArray = expandTipos(bimlinks)

      // * Asignar dbId al los tipos
      const bimlinksWithDbId = expandedArray.map(bimlink => ({
        ...bimlink,
        dbId: externalIdsMapping[bimlink.tipoExternalId],
      }))

      // * Para cada bimlink sacar su child
      const bimlinksWithChild = await Promise.all(
        bimlinksWithDbId.map(bimlink =>
          getBulkPropertiesAsync(viewer, [bimlink.dbId], ['child'])
        )
      )

      const valuesWithBimlinks = bimlinksWithChild
        .flat()
        .map(singleBimLink =>
          singleBimLink.properties.map(property => ({
            dbId: property.displayValue,
            tipobimlink: bimlinksWithDbId.find(
              bd => bd.dbId == singleBimLink.dbId
            ),
          }))
        )
        .flat()

      // * Crear los articulos que tengan el tipo 3 y no estén en BD
      await handleCreateArticulosByGroup3(valuesWithBimlinks)
      // console.log('Salgo de handleCreateArticulosByGroup3')

      // * Añadir los articulos a BD
      const allBoqWithQuantity = await handleCreateBoq(valuesWithBimlinks)

      // * Sacar para cada externalId su módulo
      const modulos =
        queryClient.getQueryData([
          'modulos-articulos',
          idProject,
          currentVersion,
        ]) || []

      // ! ADRIÁN: Aquí es donde se añade el módulo a cada boq
      const allBoqWithModulo = await addModuloToBoq(
        allBoqWithQuantity,
        modulos.modulos
      )

      let boqWithVersion = allBoqWithModulo
        .filter(item => item !== null)
        .map(boq => ({
          ...boq,
          version: Number(currentVersion),
          proyecto: idProject,
        }))

      // // * Eliminar todas las boq por proyecto y versión
      await deleteAllBoqByProjectAndVersion(idProject, currentVersion)

      // // * Crear las boq
      console.log('Boq a crear: ', boqWithVersion.length)
      await createMultipleBoq(boqWithVersion)
      toast.dismiss(loadingToast)
      queryClient.invalidateQueries([
        'modulos-articulos',
        idProject,
        currentVersion,
      ])

      toast.success(`${boqWithVersion.length} boq creadas correctamente`)
    } catch (e) {
      queryClient.invalidateQueries([
        'modulos-articulos',
        idProject,
        currentVersion,
      ])
      toast.dismiss(loadingToast)
      toast.error('Hubo un error al crear las boq. Vuelve a intentarlo')
    }
  }

  const totalImportProject = useMemo(() => {
    if (!modulos) return 0
    return modulos.modulos.reduce((acc, modulo) => {
      return acc + Number(modulo.totalImport)
    }, 0)
  }, [modulos])

  if (!modulos || isFetching) return <ArticulosTableLoader />
  return (
    <>
      <div className="fabrica-page-wrapper">
        <div className="fabrica-page-title-header"></div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <div className="fabrica-page-title">
            <div className="fabrica-page-title-buttons">
              <CustomButton
                onClick={handleOnBoq}
                type="secondary"
                className="btn-center"
                disabled={!externalIdsMapping}
              >
                <AddIcon />
                Crear BoQ
              </CustomButton>
              <CustomButton
                onClick={() =>
                  navigate(
                    changeLocation(`${location.pathname}/gestionar-celulas`)
                  )
                }
                className="btn-center"
              >
                <AdjustIcon />
                Gestionar células
              </CustomButton>
              <TotalMo />
              <CustomButton
                onClick={() =>
                  navigate(
                    changeLocation(`${location.pathname}/config-matriculas`)
                  )
                }
                className="btn-center"
              >
                <MarginIcon />
                Gestionar matrículas
              </CustomButton>
            </div>
            <FabricaPdfButton modulos={modulos} />
          </div>
          {modulos && totalMo && (
            <p className="fabrica-importe-total">
              {redondearDecimalesToLocaleString(
                totalImportProject + Number(totalMo)
              )}
            </p>
          )}
          {modulos && <FabricaMatriculasTable />}
        </div>
      </div>
    </>
  )
}

export default Fabrica
