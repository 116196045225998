import { StyleSheet } from '@react-pdf/renderer'

// Create styles
export const stylesPdf = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'Helvetica',
    paddingBottom: 45,
  },

  imageHeader: {
    width: 80,
    height: 'auto',
  },
  section: {
    padding: 20,
    backgroundColor: 'black',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleContainer: {
    paddingTop: 20,
    paddingBottom: 15,
  },

  titleWraper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  title: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 800,
    fontSize: 14,
  },

  subtitle: {
    fontFamily: 'Helvetica-Oblique',
    fontSize: 10,
    marginTop: 10,
  },

  projectImport: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 600,
    textAlign: 'right',
  },
  view: {
    height: 20,
    width: 50,
    background: 'red',
  },

  cardContainer: {
    color: 'black',
    marginTop: '15',
    border: '1px solid #c9c9c9',
    padding: 15,
    borderRadius: 5,
  },

  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
    alignItems: 'center',
    fontSize: 12,
  },
  textBold: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 800,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10',
  },
  textData: {
    fontSize: 10,
  },
  pageNumbers: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 10,
  },

  imageFooter: {
    width: 60,
    height: 'auto',
    position: 'absolute',
    bottom: 20,
    right: 35,
    fontSize: 10,
  },

  footer: {
    fontFamily: 'Helvetica-Oblique',
    position: 'absolute',
    bottom: 20,
    left: 35,
    fontSize: 10,
  },
})
